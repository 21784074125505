import React from 'react';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import { subDays, startOfDay, endOfDay } from 'date-fns';

import {
  ProviderCommentsReportTable,
  ProviderCommentsPerDayChart,
  ProviderCommentsTaggedPerDayChart,
  ProviderCommentsByLabel,
  ProviderCommentsOptions,
} from 'components';
import { PropTypes } from 'prop-types';

const styles = (theme) => ({
  container: {
    '& > *': {
      marginBottom: theme.spacing(4),
    },
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  card: {
    marginBottom: theme.spacing(3),
  },
});

const minimumStartDate = new Date(2018, 0, 1);

class ProviderCommentsReportPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDate: startOfDay(subDays(new Date(), 30)),
      endDate: endOfDay(new Date()),
      encounterType: 'All'
    };
  }

  render() {
    const { classes } = this.props;
    const { encounterType, filterUser } = this.state;
    const dateRange = {
      startDate: this.state.startDate,
      endDate: this.state.endDate
    };

    return (
      <div className={classes.container}>
        <ProviderCommentsOptions 
          dateRange={dateRange} 
          encounterType={encounterType}
          onChangeStartDate={this.onChangeStartDate}
          onChangeEndDate={this.onChangeEndDate}
          onChangeEncounterType={this.onChangeEncounterType}
          onChangeUser={this.onChangeUser}
          filterUser={filterUser}
        />
        <ProviderCommentsReportTable 
          dateRange={dateRange} 
          encounterType={encounterType}
          filterUser={filterUser}
/>
        <ProviderCommentsPerDayChart 
          dateRange={dateRange}
          encounterType={encounterType}
          filterUser={filterUser}
        />
        <ProviderCommentsTaggedPerDayChart 
          dateRange={dateRange} 
          filterUser={filterUser}
        />
        <ProviderCommentsByLabel
          dateRange={dateRange} 
          filterUser={filterUser}
        />
      </div>
    );
  }



  onChangeUser = (newUser) => {
    this.setState(
        { filterUser: newUser !== 'All Users' ? newUser : null }
    );
  };

  onChangeEncounterType = (type) => {
    this.setState({
      encounterType: type
    });
  }


  onChangeStartDate = (newDate) => {
    console.log('start date change');
    if (moment(newDate).isAfter(moment(minimumStartDate))) {
      this.setState({
        startDate: startOfDay(newDate)
      });
    }
  }

  onChangeEndDate = (newDate) => {
    console.log('end date change');
    this.setState({
      endDate: endOfDay(newDate)
    });
  }
}

ProviderCommentsReportPage.propTypes = {
  classes: PropTypes.object.isRequired,
};

const styled = withStyles(styles)(ProviderCommentsReportPage);
export { styled as ProviderCommentsReportPage };
