import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import {
  Typography,
  Card,
  CardContent,
  IconButton,
} from '@mui/material';
import { GetApp } from '@mui/icons-material';

import withStyles from '@mui/styles/withStyles';

import { withRouter } from 'utils';
import Chart from 'react-apexcharts';
import { axiosInstance, StringUtil, ColorUtil } from 'utils';
import {
  CsvUtil,
  StoreUtil,
  DateUtil,
  DataUtil,
  EncounterTypeUtil,
} from 'doctivity-shared/utils';

import { LoadingView } from 'components';
import { loadAnalytics } from 'store/actions/analyticsActions';
import { listActiveUsers } from 'store/actions/usersActions';
import fileDownload from 'js-file-download';
import moment from 'moment';

const styles = (theme) => ({
  filters: {
    '& > *': {
      marginBottom: theme.spacing(1),
    },
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    marginBottom: 10,
  },
  spacer: {
    flex: 1,
  },
  pdfButton: {
    flex: 1,
  },
  pdfIcon: {
    fontSize: 20,
    fontWeight: 200,
  },
  filterSpace: {
    width: 4,
  },
  rangeControl: {
    minWidth: 90,
  },
  userFilter: {
    minWidth: 270,
  },
  typeControl: {
    minWidth: 260,
  },
  totalCount: {
    textAlign: 'center',
    minWidth: 75
  },
  totalCountText: {
    fontSize: 24,
    fontWeight: 500,
  },
  totalCountDescriptor: {
    fontWeight: 500,
    fontSize: 14
  }
});

class ProviderCommentsPerDayChart extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filterUser: null,
      encounterType: 'All'
    };
  }

  componentDidMount() {
    if (StoreUtil.needsLoadNoCache(this.props.activityNotesPerDay)) {
      this.fetchData();
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.users) ||
      this.props.usersClientId !== this.props.app.selectedClient
    ) {
      this.fetchUsers();
    }
  }

  componentDidUpdate(prevProps) {
    // anytime the client changes
    if (this.props.app.selectedClient !== prevProps.app.selectedClient
      || this.props.encounterType !== prevProps.encounterType
      || this.props.filterUser !== prevProps.filterUser
      || this.props.dateRange.startDate.getDate() !== prevProps.dateRange.startDate.getDate()
      || this.props.dateRange.endDate.getDate() !== prevProps.dateRange.endDate.getDate()) {
      this.fetchData();
    } else if (StoreUtil.needsLoadMediumCache(this.props.activityNotesPerDay)) {
      this.fetchData();
    } else if (
      StoreUtil.hasSavedSinceUpdate(
        this.props.editComment,
        prevProps.editComment
      )
    ) {
      this.fetchData();
    }

    if (
      StoreUtil.needsLoadLongCache(this.props.users) ||
      this.props.usersClientId !== this.props.app.selectedClient
    ) {
      this.fetchUsers();
    }
  }

  getDateUnits() {
    const { startDate, endDate } = this.props.dateRange;

    return moment(endDate).diff(moment(startDate), 'days', true) > 31
      ? 'months'
      : 'days';
  }

  fetchData() {
    const { dispatch, clientId, encounterType, filterUser } = this.props;
    const { startDate, endDate } = this.props.dateRange;    

    let filter = {
      client_id: clientId,
      user_id: filterUser?.id,
      comment_date: {
        $gte: DateUtil.formatDateTimeFromDB(startDate),
        $lte: DateUtil.formatDateTimeFromDB(endDate),
      },
    };

    if (encounterType !== 'All') {
      filter.encounter_type_id = encounterType;
    }
    if (encounterType === 0) {
      filter.encounter_type_id = null;
    }

    dispatch(
      loadAnalytics({
        type: 'PROVIDER_COMMENTS_PER_DAY',
        filter: filter,
        dateUnits: this.getDateUnits(),
      })
    );
  }

  fetchUsers() {
    const { dispatch, app } = this.props;

    dispatch(listActiveUsers(app.selectedClient));
  }

  fetchCSVDownload() {
    const { startDate, endDate } = this.props.dateRange;
    const { filterUser } = this.state;
    const { clientId } = this.props;

    let filter = {
      client_id: clientId,
      comment_date: {
        $gte: DateUtil.formatDateTimeFromDB(startDate),
        $lte: DateUtil.formatDateTimeFromDB(endDate),
      },
      user_id: filterUser?.id,
    }

    axiosInstance
      .post('/Analytics', {
        type: 'PROVIDER_COMMENTS_PER_DAY',
        filter: filter,
        dateUnits: this.getDateUnits(),
        format: 'csv',
        headers: {
          'Content-Type': 'text/csv',
        },
      },
      {
        params: {
          selected_client_id: clientId,
        },
      })
      .then((response) => {
        const filename =
          filterUser && filterUser !== 'All Users'
            ? `${filterUser.first_name}_${
                filterUser.last_name
              }_doctivity_notes_per_day_${moment().format('YY_MM_DD')}.csv`
            : `doctivity_notes_per_day_${moment().format('YY_MM_DD')}.csv`;
        try {
          const data = this.formatCSV(response.data);
          fileDownload(data, filename);
        } catch (err) {
          console.error(`Could not format csv for ${filename}`);
          console.error(err);
          fileDownload(response.data, filename);
        }
      });
  }

  formatCSV(data) {
    let csvColumns = CsvUtil.stringToMatrix(data);
    if (csvColumns.length > 0) {
      let encounterTypeIndex = -1;
      let encounterDateIndex = -1;
      let encounterDayIndex = -1;
      for (let index = 0; index < csvColumns[0].length; index++) {
        if (csvColumns[0][index] === 'encounter_type_id') {
          csvColumns[0][index] = 'encounter_type';
          encounterTypeIndex = index;
        } else if (csvColumns[0][index] === 'encounter_date') {
          encounterDateIndex = index;
        } else if (csvColumns[0][index] === 'encounter_date_day') {
          encounterDayIndex = index;
        }
      }

      if (
        encounterTypeIndex > -1 &&
        encounterDateIndex > -1 &&
        encounterDayIndex > -1
      ) {
        for (let index = 1; index < csvColumns.length; index++) {
          if (csvColumns[index].length > 1) {
            const encounterType = parseInt(
              csvColumns[index][encounterTypeIndex],
              10
            );
            const encounterTypeName = EncounterTypeUtil.getName(encounterType);
            csvColumns[index][encounterTypeIndex] = encounterTypeName;

            const encounterDate = csvColumns[index][encounterDateIndex];
            const encounterDay = csvColumns[index][encounterDayIndex];
            let formattedDate = moment(encounterDate + encounterDay.padStart(2,'0'));
            formattedDate = formattedDate.format('MM/DD/YYYY');
            csvColumns[index][encounterDateIndex] = formattedDate;
          }
        }

        csvColumns = DataUtil.removeMatrixColumn(csvColumns, encounterDayIndex);
      }
    }

    return CsvUtil.matrixToString(csvColumns);
  }

  render() {
    const { classes, users, totalActivityNotes, series, categories} = this.props;

    if  (!StoreUtil.isLoaded(users)) {
      return <LoadingView />;
    }

    // calculate the steps based on maximum value

    let maxValues = [];
    let maxVal = 0;

    if (series.length>0) {
      let arr = series[0];
      arr.data.map(() => {
        maxValues.push(0);  
      })
    

      series.map((arr) => {
        let counter = 0;
        arr.data.map((val) => {
          maxValues[counter] = maxValues[counter] + val;
          counter++;
        })
      })
    
      maxVal = Math.max(...maxValues);
    }

    const stepSize = maxVal <=20 ? 1 : (maxVal <= 50 ? 5 : (maxVal <= 200 ? 10 : 25));

    return (
      <div>
        <Card>
          <CardContent>
            <div className={classes.header}>
              <Typography component='h2' variant='h6' color='primary'>
                Activity Notes Per Day
              </Typography>
              <div className={classes.pdfButton}>
                <IconButton
                  onClick={() => {
                    this.fetchCSVDownload();
                  }}
                  size='large'
                >
                  <GetApp className={classes.pdfIcon} />
                </IconButton>
              </div>
              <div className={classes.spacer} />
            </div>

            <div className={classes.filters}>
                <div className={classes.filterSpace} />
                <div className={classes.totalCount}>
                  <Typography noWrap className={classes.totalCountText}>
                    {totalActivityNotes ? totalActivityNotes : 0}
                  </Typography>
                  <Typography className={classes.totalCountDescriptor}>Total</Typography>
                </div>
                
            </div>

            <Chart
              type='bar'
              height={500}
              options={{
                chart: {
                  id: 'ActivityNotesPerDay',
                  toolbar: {
                    show: false,
                  },
                  animations: {
                    enabled: false,
                  },
                  stacked: true,
                },
                yaxis: {
                  stepSize: stepSize,
                  labels: {
                    minWidth: 100,
                    formatter: function(val) {
                      return val.toFixed(0);
                    },
                  },
                },
                xaxis: {
                  categories,
                  labels: {
                    hideOverlappingLabels: true,
                    rotate: -45,
                    rotateAlways: true
                  },
                  tickPlacement: 'on',
                },
                tooltip: {
                  enabled: true,
                  shared: true,
                  intersect: false,
                  y: {
                    formatter: StringUtil.numberForChart,
                  },
                },
                plotOptions: {
                  bar: {
                    dataLabels: {
                      total: {
                        enabled: true
                      }
                    }
                  }
                },
                dataLabels: {
                  enabled: true,
                  dropShadow: {
                    enabled: true,
                    left: 0,
                    top: 0,
                    opacity: 0.4,
                  },
                  formatter: StringUtil.numberForChart,
                  offsetY: 0,
                },
                colors: ColorUtil.getColors(7),
              }}
              series={series}
            />
          </CardContent>
        </Card>
      </div>
    );
  }

  onChangeUser = (event, newUser) => {
    this.setState(
      { filterUser: newUser !== 'All Users' ? newUser : null },
      this.fetchData
    );
  };

  onChangeEncounterType = (event) => {
    this.setState({
      encounterType: event.target.value
    }, this.fetchData);
  }
}

ProviderCommentsPerDayChart.propTypes = {
  classes: PropTypes.object.isRequired,
  dispatch: PropTypes.func.isRequired,
  clientId: PropTypes.number.isRequired,
  router: PropTypes.object.isRequired,
  app: PropTypes.object,
  user: PropTypes.object,
  users: PropTypes.object,
  usersClientId: PropTypes.number,
  editComment: PropTypes.object,
  dateRange: PropTypes.object,
  activityNotesPerDay: PropTypes.object,
  totalActivityNotes: PropTypes.number,
  series: PropTypes.object,
  categories: PropTypes.object,
  encounterType: PropTypes.object,
  filterUser: PropTypes.object
 
};

function mapStateToProps(state) {
  const { app, user, users, providerComments, analytics } = state;

    return {
      app,
      user,
      clientId: app.selectedClient,
      users: StoreUtil.get(users, StoreUtil.COMMON_LIST),
      usersClientId: users.listClientId,
      editComment: StoreUtil.get(providerComments, StoreUtil.COMMON_EDIT_ITEM),
      totalActivityNotes: analytics.totalActivityNotes,
      series: analytics.seriesActivityNotes,
      categories: analytics.categories,
      activityNotesPerDay: analytics.activityNotesPerDay || {}
    }

}

const styled = withStyles(styles)(ProviderCommentsPerDayChart);
const connected = connect(mapStateToProps)(styled);
const routed = withRouter(connected);
export { routed as ProviderCommentsPerDayChart };
